import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Breadcrumb,
  BreadcrumbItem,
  Caption,
  Grid,
  GridColumn,
  Heading,
  TabNavigation,
  TabNavigationItem,
  Button,
  Tag,
  useSnackbar,
  Skeleton,
  Divider,
  Alert,
  Spinner,
  ButtonGroup,
  Modal,
  Body,
} from "@walmart-web/livingdesign-components";
import { useHistory, useLocation, useParams } from "react-router";
import { invoiceStatus, LABEL_APPEALED, LABEL_FAILED, LABEL_REJECTED, RESUBMIT_INVOICE, RESUBMIT_INVOICE_MSG } from "../../../constants/constants";
import InvoiceFiles from "../Invoice/InvoiceFiles/InvoiceFiles";
import InvoiceLineItems from "../Invoice/InvoiceLineItems/InvoiceLineItems";
import InvoiceChangeStatusModal from "../Invoice/InvoiceChangeStatusModal/InvoiceChangeStatusModal";
import InvoiceEditModal from "../Invoice/InvoiceEditModal/InvoiceEditModal";
import {
  getService, patchService,
} from "../../../axios/axios";
import {
  setInvoiceDetail,
  resetInvoiceDetail,
  resetNonAdjustedPaginationForLineItems,
  resetAdjustedPaginationForLineItems
} from "../../../store/actions/invoiceActions";
import { INVOICE_SERVICE } from "../../../constants/baseURLs";
import ViewEditInvoice from "./ViewEditInvoice.styles";
import LoadComponent from '../../../components/LoadComponent/LoadComponent';
import { getErrorMessage, getTabList } from "../../../helpers/utils";
import { TAB_LABEL } from '../../../constants/tabLabel';
import { INVOICE_FAILURE_STATUS_MSG, INVOICE_MESSAGES, INVOICE_WARNING_MESSAGE, INVOICE_STATUS_CHANGE_SUCCESS,
  INVOICE_WARNING_TITLE_MESSAGE, WARNING_BUTTON_TITLE } from "../../../constants/messages";
import WarningPanel from "../../../components/WarningPanel/WarningPanel";
import { ChevronDown } from "@walmart-web/livingdesign-icons";
import { CANCEL, CONTINUE, EDIT, RESUBMIT } from "../../../constants/actionButtons";
import { displayLocalDate } from "../../../helpers/utcUtils";
import { amountFormat, getCurrencyAmount } from '../../../helpers/commonUtils';
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

const LoadField = ({ loader, skeletonHeight, skeletonVariant, skeletonLines = 1, node }) => {
  if (loader) {
    return <Skeleton height={skeletonHeight} variant={skeletonVariant} lines={skeletonLines} />;
  } else {
    return node;
  }
};

const InvoiceDetailsTiles = ({invoiceDetail, loading}) => {
  const classes = ViewEditInvoice();
  const loadComponentProps = {
    loader: loading,
    skeletonHeight: '20px',
    skeletonWidth: '100%',
    skeletonVariant: 'reactangle',
    skeletonLines: 1
  };

  // Function to return amount if valid, else return 0
  const findAmountFigure = (amount) => {
    return amount && amount !== '-' ? amount : 0
  }

  const totalSubmittedAmount = invoiceDetail?.totalSubmittedAmount ? getCurrencyAmount(amountFormat(invoiceDetail?.totalSubmittedAmount)) : '-';
  const totalNetAmount = invoiceDetail?.totalNetAmount ? getCurrencyAmount(amountFormat(invoiceDetail?.totalNetAmount)) : '-'
  const totalDiscounts = (invoiceDetail?.totalFeeDiscounts !== undefined || invoiceDetail?.totalExpenseDiscounts !== undefined) ?
  getCurrencyAmount(amountFormat(Number(invoiceDetail.totalFeeDiscounts + invoiceDetail.totalExpenseDiscounts)*-1)): '-';
  const totalAdjustments = (invoiceDetail?.totalFeeAdjustments !== undefined || invoiceDetail?.totalExpenseAdjustments !== undefined ) ?
    getCurrencyAmount(amountFormat(findAmountFigure(invoiceDetail?.totalSubmittedAmount)  - findAmountFigure(invoiceDetail?.totalNetAmount)
    + findAmountFigure(invoiceDetail?.totalFeeDiscounts) + findAmountFigure(invoiceDetail?.totalExpenseDiscounts))) : '-';

  return (
    <div className={classes.invoiceBadgesWrap}>
        <div className={classes.divTile}>
          <div className={classes.invoiceBadges}>
            <span>
              Total Submitted Amount
            </span>
            <Heading as="h4" size="medium" weight={700} data-testid="total-submitted-amount" id="total-submitted-amount">
              <LoadComponent {...loadComponentProps }>
                {totalSubmittedAmount}
              </LoadComponent>
            </Heading>
          </div>
          <div className={classes.divMinus}>-</div>
        </div>
        <div className={classes.divTile}>
          <div className={classes.invoiceBadges}>
            <span>
              Total Discounts
            </span>
            <Heading as="h4" size="medium" weight={700} data-testid="total-discounts" id="total-discounts">
              <LoadComponent {...loadComponentProps }>
                {totalDiscounts}
              </LoadComponent>
            </Heading>
          </div>
          <div className={classes.divMinus}>-</div>
        </div>
        <div className={classes.divTile}>
          <div className={`${classes.invoiceBadges} ${classes.invoiceBadgesLast}`}>
            <span>
              Total Adjustments
            </span>
            <Heading as="h4" size="medium" weight={700} data-testid="total-adjustments" id="total-adjustments">
              <LoadComponent {...loadComponentProps }>
                {totalAdjustments}
              </LoadComponent>
            </Heading>
          </div>
          <div className={classes.divEqual}>=</div>
        </div>
        <div className={classes.divTile}>
          <div className={classes.invoiceBadges}>
            <span>
              Total Net Amount
            </span>
            <Heading as="h4" size="medium" weight={700} data-testid="total-net-amount">
              <LoadComponent {...loadComponentProps }>
                <span id="total-net-amount">{totalNetAmount}</span>
              </LoadComponent>
            </Heading>
          </div>
        </div>
    </div>
  );
}

const TabContent = (isCurrent, loading, fetchInvoiceDetail, invoiceStatusCode, fromMatter, currentMatterTab, invoiceDetail) => {
  switch (isCurrent) {
    case TAB_LABEL.LINE_ITEMS:
      return <>
      <InvoiceDetailsTiles invoiceDetail={invoiceDetail} loading={loading}/>
      <InvoiceLineItems
        loading={loading}
        invoiceStatus={invoiceStatusCode}
        fromMatter={fromMatter}
        currentMatterTab={currentMatterTab}
      />
    </>
    case TAB_LABEL.DOCUMENTS:
      return <InvoiceFiles loading={loading} fetchInvoiceDetail={fetchInvoiceDetail} />;
    default:
      return <InvoiceLineItems loading={loading} fromMatter={fromMatter} fetchInvoiceDetail={fetchInvoiceDetail} />;
  }
};

const TabsLayout = ({ loading, listOfTab, tabIndexMap, listOfTabs, invoiceDetail ,fetchInvoiceDetail, invoiceStatusCode,fromMatter, currentMatterTab}) => {
  const classes = ViewEditInvoice();
  const [isCurrent, setIsCurrent] = useState(tabIndexMap[listOfTabs[0]]);

  return (
    <div className={classes.tabContainer} data-testid="invoice-details-tab-content">
      <TabNavigation>
        {listOfTab?.map((item, index) => (
          <TabNavigationItem
            onClick={() => setIsCurrent(tabIndexMap[item])}
            isCurrent={isCurrent === tabIndexMap[item]}
            key={tabIndexMap[item]}
            data-testid={`invoice-${item?.split(" ").join("-")}`}
            id={`invoice-${item?.split(" ").join("-")}`}
            UNSAFE_className={loading ? classes.disableTabs: ""}
          >
            {item}
          </TabNavigationItem>
        ))}
      </TabNavigation>
      {TabContent(isCurrent, loading, fetchInvoiceDetail, invoiceStatusCode, fromMatter, currentMatterTab, invoiceDetail)}
    </div>
  );
};

const InvoiceDetails = () => {
  const classes = ViewEditInvoice();
  const dispatch = useDispatch();
  const history = useHistory();
  const invoiceDetail = useSelector((state) => state?.invoice?.invoiceDetail);
  const { state: { fromMatter, currentMatterTab, matterNumber } = {} } = useLocation();
  const { invoiceId } = useParams();
  const { addSnack } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [resubmitLoading, setResubmitLoading] = useState(false);
  const [invoiceStatusCode, setInvoiceStatusCode] = useState(invoiceDetail?.status || 'Void')
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [editInvoiceModalOpen, setEditInvoiceModalOpen] = useState(false)
  const { matterDetail } = useSelector((state) => state?.matter);
  const accessibility = useSelector((state) => state?.accessibility?.applicationAccess?.financial?.invoice?.details);
  const { listOfTabs, tabIndexMap } = getTabList(accessibility?.tabs);
  const [isOpen, setIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [resubmitModalOpen, setResubmitModalOpen] = useState(false)
  const [rejectReason, setRejectReason] = useState("")
  const loadComponentProps = {
    loader: loading,
    skeletonHeight: '20px',
    skeletonWidth: '100%',
    skeletonVariant: 'reactangle',
    skeletonLines: 1
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: 'smooth' })
    fetchInvoiceDetail();
    getCommentsHistory();
    return () => dispatch(resetInvoiceDetail());
  }, []);

  /**
   * Function to fetch invoice details of specific invoice id
   */
  const fetchInvoiceDetail = () => {
    setLoading(true);
    getService(INVOICE_SERVICE, `/invoices/v1/${invoiceId}/summary`)
      .then((res) => {
        const payload = { ...res?.data };
        dispatch(setInvoiceDetail(payload));
        setInvoiceStatusCode(payload?.status || 'Void')
      }).then((data)=>{
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      });
  };

  // API to fetch commnets
  const getCommentsHistory = () => {
    getService(INVOICE_SERVICE, `/invoice-comments/v1/invoice/${invoiceId}`)
      .then((res) => {
        const externalComment = res?.data?.comments.filter((row) => row.isExternalComment === true)
        setRejectReason(externalComment?.[0]?.comment ? externalComment?.[0]?.comment : '')
      })
      .catch((error) => {
        setRejectReason('');
      });
  }

  const handleResubmit = () => {
    setResubmitLoading(true)
    patchService(INVOICE_SERVICE, `/invoices/v1/${invoiceId}/resubmit`)
      .then(() => {
        setResubmitModalOpen(false);
        addSnack({ message: INVOICE_STATUS_CHANGE_SUCCESS });
        fetchInvoiceDetail();
        setResubmitLoading(false)
      })
      .catch((error) => {
        setResubmitModalOpen(false);
        addSnack({
          message: getErrorMessage(error)
        });
        setResubmitLoading(false)
      });
  }
  const handleEditInvoice = () => {
    setEditInvoiceModalOpen(true)
  };

  const loadFieldProps = {
    loader: loading,
    skeletonHeight: '20px',
    skeletonVariant: 'rectangle',
    skeletonLines: 1,
    node: <></>
  };  

  /*
* on Click of Warning symbol in Invoice Heading
*/
  const warningHandle = () => {
    setIsOpen(true)
    setAlertMessage(INVOICE_WARNING_MESSAGE)
  }

  /**
   * MakePublicModalActions Actions
   */
  const resubmitModalActions = () => {
    return (
      <Grid>
        <GridColumn sm={5}>
          <ButtonGroup>
            <Button
              data-testid="goback-btn"
              id="goback-btn"
              size="small"
              onClick={() => setResubmitModalOpen(false)}
            >
              {CANCEL}
            </Button>
            <Button
              variant="destructive"
              size="small"
              data-testid="continue-btn"
              id="continue-btn"
              onClick={handleResubmit}
            >
              {resubmitLoading ? <Spinner size="small" color="white" /> : CONTINUE}
            </Button>
          </ButtonGroup>
        </GridColumn>
      </Grid>
    );
  };

  /**
   * Resubmit confirmation modal.
   */
  const resubmitModal = () => {
    return (
      <Modal
        onClose={() => setResubmitModalOpen(false)}
        isOpen={resubmitModalOpen}
        actions={resubmitModalActions()}
        size="medium"
        title={RESUBMIT_INVOICE}
        data-testid="makePublicModalOpen"
      >
        <Grid>
          <Body as="p" size="large">
            {RESUBMIT_INVOICE_MSG}
          </Body>
        </Grid>
      </Modal>
    );
  };

  return (
    <div className={classes.listing}>
      <Grid>
        <GridColumn sm={7}>
          {fromMatter ?
            <Breadcrumb>
              <BreadcrumbItem
                data-testid="invoice-nav-matter"
                onClick={() => history.push(`/`)}
              >
                Matters</BreadcrumbItem>
              <BreadcrumbItem
                data-testid="invoice-nav-matter-detail"
                onClick={() => history.push(`/matter/${matterDetail?.matter?.identifier}`)}
              >
                #{matterNumber}</BreadcrumbItem>
              <BreadcrumbItem
                data-testid="invoice-nav-matter-invoice-list"
                onClick={() => history.push(`/matter/${matterDetail?.matter?.identifier}`,
                  { currentMatterTab: currentMatterTab, currentFinancialTab: 1 })}>Financials</BreadcrumbItem>
              <BreadcrumbItem isCurrent>#{invoiceDetail?.organizationInvoiceNumber}</BreadcrumbItem>
            </Breadcrumb>
            :
            <Breadcrumb>
              <BreadcrumbItem data-testid="invoice-nav-matter-financial" onClick={() => history.push(`/financial`, { currentFinancialTab: 1 })}>Financials</BreadcrumbItem>
              <BreadcrumbItem isCurrent>#{invoiceDetail?.organizationInvoiceNumber}</BreadcrumbItem>
            </Breadcrumb>
          }
        </GridColumn>
        {accessibility?.edit && !loading && invoiceStatus[invoiceStatusCode]?.showHeaderActionPanel ?
          <GridColumn sm={5}>
            <div className={classes.icons}>
              {(invoiceStatusCode?.toLowerCase() === LABEL_APPEALED) &&
                <Button
                  size="small"
                  variant="tertiary"
                  onClick={handleEditInvoice}
                  data-testid="edit-invoice-btn"
                  id="edit-invoice-btn"
                  disabled={true} // This button has been disabled as per business requirement SUB: Overpaid and Underpaid Invoices in WMMS
                >
                  {EDIT}
                </Button>
              }
              {(invoiceStatusCode?.toLowerCase() === LABEL_FAILED?.toLowerCase() || invoiceStatusCode?.toLowerCase() === LABEL_APPEALED) &&
                <Button
                  size="small"
                  variant="primary"
                  onClick={()=> setResubmitModalOpen(true)}
                  data-testid="resubmit-invoice-btn"
                  id="resubmit-invoice-btn"
                >
                {RESUBMIT}
                </Button>
              }
            </div>
          </GridColumn>
          : <></>
        }
      </Grid>

      {invoiceStatusCode === LABEL_FAILED &&
        LoadField({
          ...loadFieldProps, node: <div className={classes.alertStyle} >
            <Alert
              actionButtonProps={{
                children: WARNING_BUTTON_TITLE,
              }}
              variant="warning"
              onClick={warningHandle}
              data-testid="alert-bar"
            >
              {INVOICE_WARNING_TITLE_MESSAGE}
            </Alert>
          </div>
        })
      }
      
      {invoiceDetail?.rejectReason && invoiceStatusCode?.toLowerCase() === LABEL_REJECTED.toLowerCase() &&
        LoadField({
          ...loadFieldProps, node: <div className={classes.alertStyle}>
            <Alert
              variant="error"
              data-testid="rejected-alert-bar"
            >
              Reject Reason: {invoiceDetail?.rejectReason}<br /> 
              {rejectReason && `Comment: ${rejectReason}`}
            </Alert>
          </div>
        })
      }
      <div className={classes.gridLayout}>
        <div>
          <LoadComponent {...loadComponentProps}>
            {invoiceDetail?.organizationInvoiceNumber ?
              <Heading as="h1" size="small" className={classes.pageTitle}>
                <span id="inv-det-invoiceNumber">#{invoiceDetail?.organizationInvoiceNumber}</span>
                <span className={classes.pageTitleBadge}>
                <Tag
                  color={invoiceStatus[invoiceStatusCode]?.color}
                  variant="tertiary"
                  data-testid={`invoice-status-tag-${invoiceStatusCode}`}
                  id={`invoice-status-tag-${invoiceStatusCode}`}
                >
                  {invoiceStatus[invoiceStatusCode]?.label}
                </Tag>
              </span>
              </Heading> : '-'
            }
          </LoadComponent>
        </div>
      </div>
      <div className={classes.pageTitleGroup}>
        <Caption as="small" className={classes.invoiceDateSubtitle}>
          <LoadComponent {...{ ...loadComponentProps, skeletonHeight: '21px', width: '180px' }}>
            {invoiceDetail?.startDate ?
              `${displayLocalDate(invoiceDetail?.startDate)} - ${displayLocalDate(invoiceDetail?.endDate)}`
              : '-'
            }
          </LoadComponent>
        </Caption>
      </div>
      <div className={classes.gridLayout}>
        <div>
          <div className={classes.label}>
            <Caption as="span" size="small">
              Matter Number
            </Caption>
          </div>
          <div className={classes.pageInfoBold}>
            <LoadComponent {...loadComponentProps}>
              <Heading as="h4" size="small" id={`matter-number-${invoiceDetail?.matterNumber}`}>
                {invoiceDetail?.matterNumber || '-'}
              </Heading>
            </LoadComponent>
          </div>
        </div>
        <div>
          <div className={classes.label}>
            <Caption as="span" size="large">
              Organization
            </Caption>
          </div>
          <div className={classes.pageInfoBold}>
            <LoadComponent {...{ ...loadComponentProps }}>
              <Heading as="h4" size="small">
                {invoiceDetail?.organizationName || '-'}
              </Heading>
            </LoadComponent>
          </div>
        </div>
        <div>
          <div className={classes.label}>
            <Caption as="span" size="large">
              Created Date
            </Caption>
          </div>
          <div className={classes.pageInfoBold}>
            <LoadComponent {...{ ...loadComponentProps }}>
              <Heading as="h4" size="small">
                {invoiceDetail?.createdTs ? displayLocalDate(invoiceDetail?.createdTs) : '-'}
              </Heading>
            </LoadComponent>
          </div>
        </div>
        <div>
          <div className={classes.label}>
            <Caption as="span" size="large">
              Invoice Date
            </Caption>
          </div>
          <div className={classes.pageInfoBold}>
            <LoadComponent {...{ ...loadComponentProps }}>
              <Heading as="h4" size="small">
                {invoiceDetail?.invoiceDate ? displayLocalDate(invoiceDetail?.invoiceDate) : '-'}
              </Heading>
            </LoadComponent>
          </div>
        </div>
        <div>
          <div className={classes.label}>
            <Caption as="span" size="large">
              Received Date
            </Caption>
          </div>
          <div className={classes.pageInfoBold}>
            <LoadComponent {...{ ...loadComponentProps }}>
              <Heading as="h4" size="small">
                {invoiceDetail?.receivedDate ? displayLocalDate(invoiceDetail?.receivedDate) : '-'}
              </Heading>
            </LoadComponent>
          </div>
        </div>
        <div>
          <div className={classes.label}>
            <Caption as="span" size="large">
              Type
            </Caption>
          </div>
          <div className={classes.pageInfoBold}>
            <LoadComponent {...{ ...loadComponentProps }}>
              <Heading as="h4" size="small">
                {invoiceDetail?.invoiceType || '-'}
              </Heading>
            </LoadComponent>
          </div>
        </div>
      </div>
      <TabsLayout loading={loading || isOpen || editInvoiceModalOpen || statusModalOpen} listOfTab={listOfTabs} tabIndexMap={tabIndexMap} listOfTabs={listOfTabs} invoiceDetail={invoiceDetail} fetchInvoiceDetail={fetchInvoiceDetail} invoiceStatusCode={invoiceStatusCode} fromMatter={fromMatter} currentMatterTab={currentMatterTab}/>
      <InvoiceChangeStatusModal
        open={statusModalOpen}
        actionId={''}
        handleStatusModalState={setStatusModalOpen}
        invoiceId={invoiceId}
        handleStatusCodeChange={setInvoiceStatusCode}
      />
      {editInvoiceModalOpen && <InvoiceEditModal
        openEl={editInvoiceModalOpen}
        handleModalState={setEditInvoiceModalOpen}
        invoiceDetail={invoiceDetail}
        fetchInvoiceDetail={fetchInvoiceDetail}
      />}
      {
        isOpen &&
        <WarningPanel
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title={INVOICE_MESSAGES}
          alertMessage={alertMessage}
          description={`#${invoiceDetail?.organizationInvoiceNumber}`}
        >
          <div className={classes.warning}>
            <Accordion defaultExpanded={true} elevation={0}>
              <AccordionSummary expandIcon={<ChevronDown />}>
                <div className={classes.groupHeading}>{invoiceStatus[LABEL_FAILED]?.label}</div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid>
                  <React.Fragment>
                    <GridColumn sm={12}>
                      <div className={classes.groupMessage}>{INVOICE_FAILURE_STATUS_MSG}</div>
                    </GridColumn>
                    <Divider />
                  </React.Fragment>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        </WarningPanel>
      }
      {resubmitModal()}
    </div>
  );
};

export default InvoiceDetails;
